<template>
  <div>
    <ModalCreateArticle
      v-if="showModalType === 'CreateArticle'"
      :showModal="showModalType === 'CreateArticle'"
      type="Channel"
      :itemId="channel?.Id"
      articleType="News"
      :onClickCancel="setShowModalType"
      @articleCreated="gotToArticle"
    />

    <ModalDeleteArticle
      v-if="showModalType === 'DeleteArticle'"
      :showModal="showModalType === 'DeleteArticle'"
      :article="selectedArticle"
      :onClickCancel="hideModal"
    />
    <div class="columns is-multiline">
      <div class="column is-half">
        <ui-page-hero :title="$t('Views.Articles.Main_News_Title')" />
      </div>
      <div class="column is-half">
        <Message class="is-info">
          <div class="content">
            <div class="title is-3">
              {{ $t('Views.Articles.Message_CreateArticleNews_Title') }}
            </div>
            <p>
              {{ $t('Views.Articles.Message_CreateArticleNews_Text') }}
            </p>
            <a
              @click="setShowModalType('CreateArticle')"
              class="button is-success"
            >{{ $t('Views.Articles.Message_CreateArticleNews_Button') }}</a>
          </div>
        </Message>
      </div>
    </div>
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>{{ $t('Views.Articles.TableHeader_Article') }}</th>
          <th>{{ $t('Views.Articles.TableHeader_PublishState') }}</th>
          <th>{{ $t('Views.Articles.TableHeader_ExpireDate') }}</th>
          <th>{{ $t('Views.Articles.TableHeader_Stats') }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="!isLoading">
        <tr
          v-for="article in articleResponse.Results"
          :key="'a' + article.Key"
        >
          <td>
            <div class="articleImage mr-3">
              <ImageViewer
                :itemId="article.Id"
                :aspectRatio="3 / 2"
                :imageSize="320"
                :imageSrc="article.ImageUrl"
                :itemName="article.Title"
                type="article"
                class="is-3by2"
              />
            </div>
            <div>
              <Tags
                v-bind="publishState(article)"
                class="is-inline-flex mb-1"
              />

              <div class="has-text-weight-bold">{{ article.Title }}</div>
              {{ article.Author }}
            </div>
          </td>
          <td>{{ printDate(article.Date) }}</td>
          <td>{{ printDate(article.ExpiresOn) }}</td>
          <td>
            <span
              :class="{ 'has-text-grey': !article.TimesMatched }"
              class="icon-text mr-2"
            >
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'chart-network']" />
              </span>
              <span>{{ article.TimesMatched }}</span>
            </span>
            <span
              :class="{ 'has-text-grey': !article.TimesClicked }"
              class="icon-text mr-2"
            >
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'bullseye-pointer']" />
              </span>
              <span>{{ article.TimesClicked }}</span>
            </span>
            <span
              :class="{ 'has-text-grey': !article.Views }"
              class="icon-text"
            >
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'eye']" />
              </span>
              <span>{{ article.Views }}</span>
            </span>
          </td>
          <td>
            <div class="fields">
              <p class="control">
                <LinkIcon
                  type="OnClick"
                  @click.native="gotToArticle(article)"
                  :text="$t('Form.Control.Edit')"
                />
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <Loader v-if="isLoading" />
    <Message
      v-if="!isLoading && !articleResponse.Results.length"
      class="is-info"
    >
      <div class="content">
        <p>{{ $t('Views.Articles.Message_NoNewsPosts') }}</p>
        <a
          @click="setShowModalType('CreateArticle')"
          class="button is-success"
        >{{ $t('Views.Articles.Message_CreateArticleNews_Button') }}</a>
      </div>
    </Message>
  </div>
</template>

<script>
import ModalCreateArticle from '../../components/Article/ModalCreateArticle.vue'
import ModalDeleteArticle from '../../components/Article/ModalDeleteArticle.vue'
import ImageViewer from '../../components/UI/Components/ImageViewer.vue'
import LinkIcon from '../../components/UI/LinkIcon.vue'
import Loader from '../../components/UI/Loader.vue'
import Message from '../../components/UI/Message.vue'
import Tags from '@/components/UI/Tags.vue'
import articleProvider from '../../providers/article'
import parseISO from 'date-fns/esm/parseISO/index.js'
import intlFormat from 'date-fns/intlFormat'
import { mapState } from 'vuex'
export default {
  name: 'NewsList',

  components: {
    ModalCreateArticle,
    ModalDeleteArticle,
    ImageViewer,
    LinkIcon,
    Loader,
    Message,
    Tags,
  },

  data() {
    return {
      firstLoad: true,
      isLoading: true,
      articleResponse: {
        MorePages: 0,
        Results: [],
      },
      page: 1,
      itemsPerPage: 50,
      showModalType: '',
      selectedArticle: null,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel'])
  },

  created() {
    this.getArticles()
  },

  methods: {
    async getArticles() {
      this.isLoading = true
      try {
        let response = await articleProvider.methods.getChannelArticles({
          channelId: this.channel.Id,
          page: this.page,
          itemsPerPage: this.itemsPerPage,
          type: 'News',
        })
        this.articleResponse = response.data
      } catch (e) {
        //console.log(e)
      }
      this.firstLoad = false
      this.isLoading = false
    },

    setShowModalType(type = '', article = null) {
      this.showModalType = type
      this.selectedArticle === article
    },

    gotToArticle(article) {
      this.$router.push({
        name: 'news-edit',
        params: {
          key: article.Key,
        },
      })
    },

    printDate(dateTime) {
      if (dateTime.indexOf('1900') !== -1) {
        return '-'
      }
      return intlFormat(
        parseISO(dateTime),
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        },
        {
          locale: this.$i18n.locale,
        }
      )
    },

    publishState(article) {
      let output = {
        nrOfTags: 0,
        tags: '',
        tagCssClass: '',
      }

      if (article.Status === 'Draft') {
        output.tagCssClass = 'is-warning is-normal'
        output.tags = this.$t(
          'Components.Article.ArticleDetail.Text_ArticleStateDraft'
        )
      }

      if (article.Status === 'Retracted') {
        output.tagCssClass = 'is-danger is-normal'
        output.tags = this.$t(
          'Components.Article.ArticleDetail.Text_ArticleStateRetracted'
        )
      }

      if (article.Status === 'Published') {
        output.tagCssClass = 'is-success is-normal'
        output.tags = this.$t(
          'Components.Article.ArticleDetail.Text_ArticleStatePublished'
        )
      }

      return output
    },
  },
}
</script>

<style lang="sass" scoped>
.articleImage
  max-width: 150px
  width: 100%
  float: left
</style>
